<template>
  <div>
    <nav-bar/>
    <div class="scoreTopImg">
      <img src="../../assets/images/scoreImg.jpg" class="scoreImgContent"/>
    </div>
    <div class="scoreMainWrap">
      <div class="scoreMain">
        <div class="firstCont">
          <h3>小店业务-啥都能做</h3>
          <div class="line"></div>
          <span class="firstSPen">专业团队 一站式服务</span>
          <div class="signList">
            <span>体验<br />提升</span>
            <span>过新<br />手期</span>
            <span>强开<br />精选</span>
          </div>
        </div>
        <!-- <div class="rightImg">
          <img src="../../assets/images/score1.jpg" class="rightScore"/>
        </div> -->
        <div class="leftImg">
          <img src="../../assets/images/score2.jpg" class="leftScore"/>
          <div>
            <p class="titleText">源头50人团队， 自建分拨中心仓库，300+产品，各个类目应对。<br />千川投流稳定不清分！！ 价格超低！！ </p>
            <p class="titleText" style="color: #f66;">招商+招代理  欢迎同行比价咨询合作！！！！</p>
            <img src="../../assets/images/score3.jpg" class="wxImg"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from 'components/navbar/NavBar'
export default {
  components: {
    NavBar
  }
}
</script>

<style scoped>
  .scoreTopImg{
    width: 100%;
    height: 180px;
  }
  .scoreImgContent{
    width: 100%;
    height: 180px;
  }
  .scoreMainWrap{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #efefef;
    min-height: 80vh;
  }
  .scoreMain{
    background: #fff;
    width: 1200px;
  }
  .rightImg{
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .rightScore{
    width: 240px;
    height: 240px;
  }
  .leftScore{
    width: 300px;
    height: 500px;
    transform: rotate3d(30deg);
  }
  /* .leftScore:hover{
    transform: skew(-10deg, -10deg);
    transform-origin: left center;
  } */
.leftImg{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: -20px;
}
.titleText{
  font-size: 18px;
  color: #333;
  line-height: 30px;
}
.wxImg{
  width: 220px;
  height: 300px;
}
.firstCont{
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.firstCont h3{
  color: #666;
  font-size: 30px;
  font-weight: 400;
}

.line{
  background-color: #ccc;
  width: 200px;
  height: 2px;
  margin: 10px 0 10px;
}
.firstSPen{
  color: #999;
}
.signList{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 40px;
  width: 100%;
}
.signList span{
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 50%;
  border: 4px solid transparent;
  font-weight: 600;
}
.signList span:nth-child(1){
  color: #d15d06;
  border-color: #d15d06;
}
.signList span:nth-child(2){
  color: #409Eff;
  border-color: #409Eff;
}
.signList span:nth-child(3){
  color: #5cb87a;
  border-color: #5cb87a;
}
</style>
